import React, {FC, useCallback, useMemo} from 'react';
import './menu.scss'
import {NavLink} from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import {clearTokensLocalStorage} from "../../api/axiosInstances";
import {Page} from "../../common/types";
import Notifications from '../notifications/Notifications';
import {useAppSelector} from "../../redux/hooks";
import {selectCountUnreadNotifications} from "../../redux/selectors/selectors";
import CartMenu from "./CartMenu";
import ExitIcon from "../../assets/icons/logout.svg"

interface ToolbarMenuProps {
    pages: Page[];
}

const ToolbarMenu: FC<ToolbarMenuProps> = ({pages}) => {
    const logoutHandle = useCallback(() => {
        clearTokensLocalStorage()
        window.location.replace('/auth')
    }, [])

    const sortPages = useMemo(() => pages.filter(i => (!i.notInMenu && !i.isBase))
        .sort((a, b) => a.id - b.id), [])

    const cartsMenu = useMemo(() => pages.find(p => p.path.includes('cart')), []);

    return (
        <>
            <div className="toolbarMenu">
                {
                    sortPages.map(p => {
                        if (p.path.includes("profile"))
                            return <NavDropdown key={p.path} className="link exp"
                                                title={(<div className="link ps-0">
                                                    <img src={p.icon} alt={p.name} loading="lazy"/>
                                                    <span className="wrapTitle">Профиль</span>
                                                </div>)}>

                                <NavDropdown.Item href="#">
                                    <NavLink className="link option" to="/profile">
                                        <img loading="lazy" style={{padding: 6}} width={32} height={32} src={p.icon} alt={p.name} />
                                        <span className="wrapTitle">{p.name}</span>
                                    </NavLink>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#" onClick={logoutHandle}>
                                    <div className="link option">
                                        <img width={32} height={32} src={ExitIcon} alt="exit" loading="lazy"/>
                                        <span className="wrapTitle">Выход</span>
                                    </div>
                                </NavDropdown.Item>
                            </NavDropdown>

                        if (p.path.includes('catalog'))
                            return <NavLink key={p.path} className="link" to={p.path + '#divider-main-banner'}>
                                <img src={p.icon} alt={p.name} loading="lazy"/>
                                <span className="wrapTitle">{p.name}</span>
                            </NavLink>

                        if (p.path.includes('contacts'))
                            return <NavLink key={p.path} className="link contacts-menu" to={p.path + '#divider-main-banner'}>
                                <img src={p.icon} alt={p.name} loading="lazy"/>
                                <span className="wrapTitle">{p.name}</span>
                            </NavLink>

                        return <NavLink key={p.path} className="link" to={p.path}>
                            <img src={p.icon} alt={p.name} loading="lazy"/>
                            <span className="wrapTitle">{p.name}</span>
                        </NavLink>
                    })
                }
            </div>
            <div className="baseMenu">
                {
                    cartsMenu && <CartMenu key={cartsMenu.path} page={cartsMenu}/>
                }
                <Notifications/>
            </div>
        </>
    );
}

export default ToolbarMenu;
