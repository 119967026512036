import React, {FC, useCallback, useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IFilter, TFilter} from "../../entity/filter";
import CartService from "../../entity/cart/CartService";
import {setCart, setCountItemsInCart} from "../../redux/reducers/cartSlice";
import Badge from "../badge/Badge";
import DataFormat from "../../utils/DataFormat";
import {NavLink} from "react-router-dom";
import {Page} from "../../common/types";

interface CartMenuProps {
    page: Page
}

const CartMenu: FC<CartMenuProps> = ({page}) => {
    const dispatch = useAppDispatch()
    const {currentMealRef, currentDate, currentDivision} =
        useAppSelector(state => state.divisionReducer)
    const {personnelNumber} = useAppSelector(state => state.profileReducer)
    const {cart, countItemsInToCart} = useAppSelector(state => state.cartReducer)
    const abortController = useRef(new AbortController());

    useEffect(() => {
        if (currentDate && currentMealRef && currentDivision?.active) {
            const fetchData = async ({filter, personnelNumber}: { filter: IFilter, personnelNumber: string }) => {
                if (filter.isOk()) {
                    abortController.current = new AbortController()
                    const signal = abortController.current.signal;
                    const response = await CartService.cart(filter.apiDate(),
                        filter.mealId, filter.divisionId, personnelNumber, signal);
                    dispatch(setCart(response.body))
                    dispatch(setCountItemsInCart(response.body.items.length))
                    abortController.current.abort();
                }
            }

            fetchData({
                filter: new TFilter(currentDate, currentDivision.divisionRef, currentMealRef),
                personnelNumber: personnelNumber
            }).catch(e => {})
        }

        return () => {
            abortController.current.abort();
        }
    }, [currentDivision, currentDate, currentMealRef, personnelNumber])

    const isAmount = !!cart?.amount

    return (
        <NavLink key={page.path} className="cartMenu link exp" to={page.path + '#divider-main-banner'}>
            <img src={page.icon} alt={page.name}/>
            <Badge count={countItemsInToCart} position={"relative"}
                   style={{transform: 'translate(100%, -60%)'}}/>
            <span className={`wrapTitle${isAmount ? ' amount' : ''}`} data-is-fill={isAmount}>
                {
                    !isAmount ? page.name : DataFormat.currencyRUB(cart?.amount)
                }
            </span>
        </NavLink>
    )
}

export default CartMenu;