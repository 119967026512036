import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import './notification.scss'
import useScrollLock from "../../hooks/useScrollLock";
import NavDropdown from "react-bootstrap/NavDropdown";
import BellIcon from "../../assets/icons/bell.svg"
import {Dropdown} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {notificationApi} from "../../api/notificationApi";
import Loader from "../UI/loader/Loader";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {INotificationDto, STATUS_NOTIFICATION} from "../../entity/notification";
import {useNavigate} from "react-router-dom";
import {push} from "../../redux/reducers/alertSlice";
import {Alert, TYPE_ALERT} from "../../entity/notification/alert";
import DataFormat from "../../utils/DataFormat";
import Badge from "../badge/Badge";
import useClickOutside from "../../hooks/useClickOutside";
import {useInView} from "react-intersection-observer";
import {selectCountUnreadNotifications} from "../../redux/selectors/selectors";

interface NotificationItemProps {
    onClick: () => void
    notification: INotificationDto
}

const NotificationItem: FC<NotificationItemProps> = ({onClick, notification}) => {
    const isUnRead = useMemo(() => (notification.status === STATUS_NOTIFICATION.UNREAD ||
        notification.status === STATUS_NOTIFICATION.NEW), [notification.status]);

    return (
        <Dropdown.Item onClick={onClick} className="itemNotification" title={isUnRead ? 'Не прочитано' : ''}>
            <label className={`title${isUnRead ? ' unRead' : ''}`}>
                {notification.title}
            </label>
            <div className="message">
                {notification.message}
            </div>
        </Dropdown.Item>
    );
}

interface NotificationsProps {
    onClickNotification?: () => void
}

const Notifications: FC<NotificationsProps> = ({onClickNotification}) => {
    const navigate = useNavigate()
    const countNotifications = useAppSelector(state => selectCountUnreadNotifications(state))
    const dispatch = useAppDispatch()
    const ref = useRef(null);
    const {lockScroll, unlockScroll, isLockScroll} = useScrollLock();
    useClickOutside({
        ref,
        handleOutside: unlockScroll,
        conditionClick: () => !!document.getElementsByClassName('dropdownNotification show').length
    })
    const [notifications, setNotifications] = useState<INotificationDto[]>([]);
    const {personnelNumber} = useAppSelector(state => state.profileReducer)
    const [fetchGetAll, {error, data, isLoading, isSuccess}] = notificationApi.useLazyGetAllNotificationsQuery()
    const [fetchReadAll] = notificationApi.useReadAllNotificationsMutation()
    const [fetchReadById] = notificationApi.useReadByIdNotificationsMutation()
    const [page, setPage] = useState(0);
    const {ref: refLoadBottom, inView, entry} = useInView({
        // threshold: 0,
        onChange: (inView, entry) => {
            if (inView) {
                getNotifications(true)
            }
        }
    })

    useEffect(() => {
        getNotifications()
    }, [personnelNumber]);

    const getNotifications = (isNextPage?: boolean) => {
        if (personnelNumber)
            fetchGetAll({personnelNumber, page: isNextPage ? page : 0}).then((res) => {
                if (res?.data) {
                    res.data.body.content.filter(e => e.status === STATUS_NOTIFICATION.NEW).forEach(e => {
                        dispatch(push(Alert.Extend(e.ref, e.title, e.message,
                            TYPE_ALERT.alert, false, e
                        )))
                    })

                    if (!res.data.body.last) {
                        setPage(res.data.body.number + 1)
                    }

                    if (res.data.body.totalElements > notifications.length) {
                        if (isNextPage) {
                            setNotifications(prevState => ([...prevState, ...res?.data?.body?.content ?? []]))
                        } else {
                            setNotifications(res.data.body.content)
                        }
                    }
                }
            })
    }

    const goToLinkNotification = useCallback((notification: INotificationDto) => {
        let path = DataFormat.pathForNotification(notification)

        if (path && !!notification?.ref) {
            if (notification.status === STATUS_NOTIFICATION.UNREAD)
                fetchReadById({id: notification?.ref, personnelNumber: notification.personnelNumber}).then(null)

            navigate(path)
        }
    }, [])

    const isEmpty = useMemo(() => !!data && data.body.totalElements === 0, [data])

    const clearAllNotifications = useCallback(async () => {
        if (countNotifications) {
            await fetchReadAll({personnelNumber}).finally(() => {
                setNotifications(prevState => (prevState.map(n => ({...n, status: STATUS_NOTIFICATION.READ}))))
            });
        }
    }, [countNotifications])

    const showList = useCallback(() => {
        if (!isLockScroll())
            lockScroll()
        else
            unlockScroll()
    }, [])

    const goToDetails = useCallback((notification: INotificationDto) => {
        return () => {
            unlockScroll()
            goToLinkNotification(notification)
            onClickNotification && onClickNotification()
        }
    }, [])

    return (
        <NavDropdown title={<>
            <Badge count={countNotifications} position={"relative"} style={{transform: 'translate(100%, -60%)'}}/>
            <img loading="lazy" height={32} width={32} src={BellIcon} onClick={showList} alt="notifications"/>
        </>} className="link exp dropdownNotification" ref={ref}>
            <div className={`listNotification scrollWrap`}>
                {
                    (isLoading && (<Loader/>)) || (isEmpty && (<div className="empty">Нет уведомлений</div>))
                    || notifications.map((e) =>
                        (<NotificationItem key={e.ref} notification={e} onClick={goToDetails(e)}/>))
                }
                {
                    !data?.body.last && <div ref={refLoadBottom} style={{
                        display: "flex", alignItems: "center",
                        width: "100%", justifyContent: "center"
                    }}><Loader size={"sm"}/></div>
                }
            </div>
            <Dropdown.Divider/>
            <Button active={!countNotifications} disabled={!countNotifications} className={`remove`}
                    variant={"outline-primary"} onClick={clearAllNotifications}>
                Прочитать все
            </Button>
        </NavDropdown>
    )

}

export default Notifications;
