import React, {useLayoutEffect, useState} from 'react';
import {NavLink, Outlet} from "react-router-dom";
import RightMenu from "../components/toolbar/RightMenu";
import {RFC, Page} from "../common/types";
import "./layout.scss"
import Toolbar from "../components/toolbar";
import ConfirmDialogProvider from "../components/dialogs/ConfirmDialogContext";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import Container from "react-bootstrap/Container";
import AuthService from "../entity/auth/AuthService";
import FullPageLoading from "../components/UI/loader/FullPageLoading";
import {useAppDispatch} from "../redux/hooks";
import {setPersonalNumber} from "../redux/reducers/profileSlice";
import InfoCartBanner from "../components/banners/InfoCartBanner";
import ToolbarMenu from "../components/toolbar/ToolbarMenu";
import ErrorBlock from "../components/info/ErrorBlock";
import Center from "../components/UI/layouts/Center";

interface IProps {
    pages: Page[];
}

interface LayoutState {
    isLoading: boolean
    checkAuth: null | string
}

const Layout: RFC<IProps> = ({pages}) => {
    const dispatch = useAppDispatch()
    const [state, setState] = useState<LayoutState>({
        checkAuth: null,
        isLoading: true
    });

    useLayoutEffect(() => {
        AuthService.getUserInfo2().then(resp => {
            if (resp?.status === 200) {
                setState({isLoading: false, checkAuth: null})
            }

            if (resp.body) {
                dispatch(setPersonalNumber(resp.body.personnelNumber))
            }
        }).catch(e => {
            setState(prevState => ({isLoading: false, checkAuth: "Не удалось загрузить страницу"}))
        })
    }, [])

    return (
        <>
            {
                state.isLoading ? <FullPageLoading/> :
                    (state.checkAuth &&
                        <Center>
                            <ErrorBlock error={state.checkAuth}/>
                        </Center>) || <ConfirmDialogProvider>
                            <Container className="mainContainer">
                                <InfoCartBanner/>
                                <Toolbar
                                    rightElements={<RightMenu id="menu" pages={pages} placement={"end"} name={"menu"}/>}
                                    leftElements={<ToolbarMenu pages={pages}/>}/>
                                <Outlet/>
                            </Container>
                            <div className="appFooter">
                                <NavLink to="contacts">Контакты</NavLink>
                                <NavLink to="help#about">Вопросы и ответы</NavLink>
                            </div>
                            <ConfirmDialog/>
                        </ConfirmDialogProvider>
            }
        </>
    );
};

export default Layout;
