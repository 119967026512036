import React, {lazy, Suspense} from "react";
import "./css/global.scss"
import {Route, Routes} from "react-router-dom";
import {Page} from "./common/types";
import Layout from "./layout/Layout";
import HomeIcon from "./assets/icons/home.svg";
import OrdersIcon from "./assets/icons/orders.svg";
import PhoneIcon from "./assets/icons/phone.svg";
import ProfileIcon from "./assets/icons/profile.svg";
import CartIcon from "./assets/icons/cart.svg";
import ExitIcon from "./assets/icons/logout.svg";
import {QuestionCircleFill} from "react-bootstrap-icons";

const Profile = lazy(() => import("./pages/profile/Profile"));
const Catalog = lazy(() => import("./pages/catalog/Catalog"));
const NotFound = lazy(() => import("./pages/notFound/NotFound"));
const Carts = lazy(() => import("./pages/carts/Carts"));
const Orders = lazy(() => import("./pages/orders/Orders"));
const Logout = lazy(() => import("./pages/Logout"));
const Order = lazy(() => import("./pages/order/Order"));
const ContactsPage = lazy(() => import( "./pages/contacts/ContactsPage"));
const HelpPage = lazy(() => import( "./pages/help/HelpPage"));
const AuthPage = lazy(() => import( "./pages/auth/AuthPage"));
const AuthAdmin = lazy(() => import( "./pages/auth/AuthAdmin"));
const AuthBase = lazy(() => import( "./pages/auth/AuthBase"));
const RedirectPage = lazy(() => import( "./pages/auth/RedirectPage"));

const pages: Page[] = [
    {
        id: 0,
        path: "catalog",
        name: "Меню",
        element: <Catalog/>,
        icon: HomeIcon
    },
    {
        id: 1,
        path: "orders",
        name: "Заказы",
        element: <Orders/>,
        icon: OrdersIcon
    },
    {
        id: 2,
        path: "contacts",
        name: "Контакты",
        element: <ContactsPage/>,
        icon: PhoneIcon
    },
    {
        id: 3,
        path: "profile",
        name: "Личный кабинет",
        element: <Profile/>,
        icon: ProfileIcon
    },
    {
        id: 8,
        path: "orders/:divisionId/:id",
        name: "Заказ",
        element: <Order/>,
        icon: OrdersIcon,
        notInMenu: true
    },
    {
        id: 4,
        path: "cart",
        isBase: true,
        name: "Корзина",
        element: <Carts/>,
        icon: CartIcon
    },
    {
        id: 5,
        path: "logout",
        name: "Выход",
        notInMenu: true,
        element: <Logout/>,
        icon: ExitIcon
    },
    {
        id: 6,
        path: "help",
        name: "Справка",
        notInMenu: true,
        element: <HelpPage/>,
        icon: <QuestionCircleFill/>
    }
]

function App() {
    return (
        <Suspense fallback={<></>}>
            <Routes>
                <Route path="/" element={<Layout pages={pages}/>}>
                    <Route index element={<Catalog/>}/>
                    {
                        pages.map(p => {
                            return <Route key={p.path} path={p.path} element={p.element}/>
                        })
                    }
                    <Route path="*" element={<NotFound/>}/>
                </Route>
                <Route path="auth" element={<AuthPage/>}>
                    <Route index element={<AuthBase/>}/>
                    <Route path="redirect" element={<RedirectPage/>}/>
                    <Route path="secure-auth" element={<AuthAdmin/>}/>
                </Route>
            </Routes>
        </Suspense>
    );
}

export default App;
