import {FC} from "react";
import ListGroup from "react-bootstrap/ListGroup";
import {MenuItemType} from "../../common/types";
import "./menu.scss"

interface IProps {
    data: MenuItemType
    className?: string
    onCLick?: () => void
    countNotifications?: number
}

const MenuItem: FC<IProps> = ({data, countNotifications = 0, onCLick, className}) => {

    return (
        <ListGroup.Item as="li" className={`mainMenuItem ${className ? className : ''}`} onClick={onCLick}>
            <span className="icon"><img loading="lazy" src={data.icon} alt={data.name}/></span><span className="title">{data.name}</span>
            {
                countNotifications > 0 &&
                <span style={{zIndex: 2, left: '90%'}}
                      className="position-absolute top-50 translate-middle badge rounded-pill bg-danger">{countNotifications}</span>
            }
        </ListGroup.Item>
    )
}

export default MenuItem
